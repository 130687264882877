import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import colors from '../_vars/colors'

import './global.scss'

const Global = styled.div`
  background-color: ${colors.lightGray1};
`

class GlobalLayout extends React.Component {
  render() {
    // const projects = this.props.projects.edges
    return (
      <Global onScroll={this.incrementScroll}>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://fonts.typotheque.com/WF-032772-010620.css"
            type="text/css"
          />
        </Helmet>
        <div>{this.props.children}</div>
      </Global>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        projects: allMdx(
          sort: { order: ASC, fields: [frontmatter___project] }
        ) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                project
                designer
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={({ projects }) => <GlobalLayout projects={projects} {...props} />}
  />
)
