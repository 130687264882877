const colors = {
  background: '#222',
  lightGray0: 'hsl(216, 5%, 98%)',
  lightGray1: 'hsl(216, 5%, 94%)',
  lightGray2: 'hsl(216, 11%, 88%)',
  lightGray3: 'hsl(216, 14%, 75%)',
  lightGray4: 'hsl(216, 20%, 60%)',
  homeBackground: '#222',
  card: '#222',
  cardDarker: '#1c1c1c',
  accent: '#fff',
  accentColorful: '#005EFF',
  accentColorful2: '#0e4aca',
  text: '#000',
  lightText0: '#666',
  lightText: 'rgba(0,0,0,0.75)',
  lightText2: 'rgba(0,0,0,0.25)',
  shadow: '0 0 10px rgba(0,0,0,0.75)',
}

export default colors
